import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay, isValid, toDate } from 'date-fns';

@Pipe({ name: 'isRequestedDifferentHotelCheckInCheckOutDates' })
export class IsRequestedDifferentHotelCheckInCheckOutDatesPipe implements PipeTransform {
  transform(
    arrivalDate: string,
    departureDate: string,
    requestedArrivalDate: string,
    requestedDepartureDate: string,
  ): boolean {
    const arrivalDateFsn = toDate(arrivalDate);
    const departureDateFsn = toDate(departureDate);
    const requestedArrivalDateFsn = toDate(requestedArrivalDate);
    const requestedDepartureDateFsn = toDate(requestedDepartureDate);
    if (
      ![arrivalDateFsn, departureDateFsn, requestedArrivalDateFsn, requestedDepartureDateFsn].map(toDate).every(isValid)
    ) {
      return false;
    }

    return (
      !isSameDay(arrivalDateFsn, requestedArrivalDateFsn) || !isSameDay(departureDateFsn, requestedDepartureDateFsn)
    );
  }
}
