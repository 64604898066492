<ag-shr-agent-button
  [agentDbId]="(owner$ | async)?.assignedTo"
  (handleClick)="showAssignOwnerModalClick()"
></ag-shr-agent-button>

<ag-shr-assign-owner-modal
  #assignOwnerModalRef
  [listLoadOptions]="listLoadOptions"
  (updatedOwner)="onUpdateOwner($event)"
></ag-shr-assign-owner-modal>
