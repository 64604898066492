import {
  IndexProperties,
  IndexPropertiesMap,
  MappingNestedProperty,
  ScriptSort,
  SearchRequest,
  SearchTotalHits,
  Sort,
  SupportedCollectionsKeys,
} from '@ag-common-lib/public-api';
import { LoadOptions, SearchOperation } from 'devextreme/data';
import { CloudFunctionsService } from '../cloud-functions.service';
import { firstValueFrom, Observable } from 'rxjs';
import { AggregationsAggregationContainer } from '@elastic/elasticsearch/lib/api/types';

export enum DxFilterOperators {
  equal = '=',
  doNotEqual = '<>',
  more = '>',
  moreOrEqual = '>=',
  less = '<',
  lessOrEqual = '<=',
  between = 'between',
  startsWith = 'startswith',
  endsWith = 'endswith',
  contains = 'contains',
  notContains = 'notcontains',
  or = 'or',
  and = 'and',
}

export enum QueryOperation {
  must = 'must',
  should = 'should',
  mustNot = 'must_not',
}

export class BaseElasticSearchService<T> {
  private readonly index: string;
  private readonly aggregations: Record<string, AggregationsAggregationContainer>;
  protected defaultSorting: Sort = ['_doc'];
  protected cloudFunctionsService: CloudFunctionsService;
  private indexProperties: IndexProperties;
  protected readonly sortingMappings: Map<string, Observable<{ [key: string]: string }>> = new Map();

  constructor(collection: SupportedCollectionsKeys, aggregations?: Record<string, AggregationsAggregationContainer>) {
    this.index = `search-${collection}`;
    this.aggregations = aggregations;
    this.indexProperties = IndexPropertiesMap.get(collection);
  }

  convertFilter(filter: any[]): any {
    const queries = {
      [QueryOperation.must]: [],
      [QueryOperation.should]: [],
      [QueryOperation.mustNot]: [],
    };

    const firstOperand = filter[0];
    const filterLength = filter?.length;

    if (firstOperand === '!') {
      const bool = this.convertFilter(filter[1]);

      return {
        [QueryOperation.mustNot]: [
          {
            bool,
          },
        ],
      };
    }

    if (typeof firstOperand === 'string') {
      const mappingProperty = this.indexProperties[firstOperand];
      const selectedFilterOperations = filterLength === 3 ? filter?.[1] : DxFilterOperators.equal;
      const secondOperand = filter?.[filterLength - 1];
      const query =
        mappingProperty.type === 'nested'
          ? this.getNestedQuery(firstOperand, mappingProperty, secondOperand, selectedFilterOperations)
          : this.getQuery(firstOperand, secondOperand, selectedFilterOperations);

      switch (selectedFilterOperations) {
        case DxFilterOperators.notContains:
        case DxFilterOperators.doNotEqual:
          queries[QueryOperation.mustNot].push(query);
          break;

        default:
          queries[QueryOperation.must].push(query);
      }

      return queries;
    }

    const operation = filter.find(item => typeof item === 'string') ?? 'and';
    filter.forEach(item => {
      if (!Array.isArray(item)) {
        return;
      }
      const bool = this.convertFilter(item);

      switch (operation) {
        case 'or':
          queries[QueryOperation.should].push({
            bool,
          });
          return;
        case 'and':
          queries[QueryOperation.must].push({
            bool,
          });
          return;
      }
    });

    return queries;
  }

  async getByIds(ids: string[]): Promise<Array<T | null>> {
    if (!ids || !ids?.length) {
      return [];
    }
    const payload: SearchRequest = {
      index: this.index,
      filter_path: ['hits.hits._source'],
      query: {
        ids: {
          values: ids,
        },
      },
    };

    const response = await this.cloudFunctionsService.searchWithElastic(payload);

    return response?.data?.hits?.hits?.map(hit => {
      return hit?._source as T;
    });
  }

  async getById(id: string): Promise<T | null> {
    if (!id) {
      return null;
    }
    const payload: SearchRequest = {
      index: this.index,
      filter_path: ['hits.hits._source'],
      query: {
        term: {
          _id: id,
        },
      },
    };

    const response = await this.cloudFunctionsService.searchWithElastic(payload);

    return (response?.data?.hits?.hits?.[0]?._source as T) ?? null;
  }

  getFromElastic = async (
    param: LoadOptions,
    isLoadingAll = false,
  ): Promise<{
    data: any;
    totalCount: number;
    aggregations?: any;
  }> => {
    const { sort, take, filter, searchExpr, searchOperation, searchValue } = param;

    const payload: SearchRequest = {
      index: this.index,
      size: take ?? 20,
      from: param.skip ?? 0,
      sort: this.defaultSorting,
    };

    if (this.aggregations) {
      payload.aggregations = this.aggregations;
    }

    if (sort) {
      const normalizedSort = [];
      if (Array.isArray(sort)) {
        for (const sortRule of sort) {
          const normalizedRule = await this.normalizeSort(sortRule);
          normalizedSort.push(...normalizedRule?.flat(1));
        }
      } else {
        const normalizedRule = await this.normalizeSort(sort);
        normalizedSort.push(...normalizedRule?.flat(1));
      }
      normalizedSort.push('_doc');
      Object.assign(payload, { sort: normalizedSort });
    }

    if (filter?.length) {
      const bool = this.convertFilter(filter);

      Object.assign(payload, {
        query: {
          bool,
        },
      });
    }

    if (searchExpr && searchValue) {
      Object.assign(payload, this.buildSearchQuery(searchExpr, searchOperation, searchValue));
    }

    if (isLoadingAll) {
      return this.getAllData(payload);
    }

    const response = await this.getData(payload);

    return response;
  };

  private async getAllData(payload: SearchRequest) {
    let searchAfter;
    const data = [];

    const getItems = async () => {
      const params: SearchRequest = Object.assign(payload, { size: 500 });

      if (data?.length && searchAfter) {
        Object.assign(params, { search_after: searchAfter, from: 0 });
      }

      const response = await this.getData(params);

      if (!response || !response?.data?.length) {
        return;
      }
      searchAfter = response?.lastHit?.sort;
      data.push(...response.data);

      if (data?.length === response.totalCount) {
        return;
      }

      return getItems();
    };

    await getItems();

    return { data, totalCount: data?.length };
  }

  private async getData(payload: SearchRequest): Promise<any> {
    const response = await this.cloudFunctionsService.searchWithElastic(payload);

    const totalCount = (response.data.hits.total as SearchTotalHits)?.value;
    const hits = response?.data?.hits?.hits ?? [];
    const lastHit = hits[hits?.length - 1];
    const data = [];

    hits?.forEach(hit => {
      data.push(hit?._source);
    });

    return { data, totalCount, lastHit, aggregations: response?.data?.aggregations };
  }

  private getQuery(dataField: string, value, selectedFilterOperations: DxFilterOperators) {
    switch (selectedFilterOperations) {
      case DxFilterOperators.equal:
      case DxFilterOperators.doNotEqual:
        return this.getEqualQuery(dataField, value);
      case DxFilterOperators.startsWith:
        return this.getStartsWithQuery(dataField, value);
      case DxFilterOperators.endsWith:
        return this.getEndWithQuery(dataField, value);
      case DxFilterOperators.more:
        return this.getMoreQuery(dataField, value);
      case DxFilterOperators.moreOrEqual:
        return this.getMoreOrEqualQuery(dataField, value);
      case DxFilterOperators.less:
        return this.getLessQuery(dataField, value);
      case DxFilterOperators.lessOrEqual:
        return this.getLessOrEqualQuery(dataField, value);
      case DxFilterOperators.between:
        return this.getBetweenQuery(dataField, value);
      case DxFilterOperators.contains:
      case DxFilterOperators.notContains:
      default:
        return this.getContainsQuery(dataField, value);
    }
  }

  protected normalizeSort = async sortDescriptor => {
    const selector = typeof sortDescriptor === 'string' ? sortDescriptor : sortDescriptor.selector;
    const desc = typeof sortDescriptor === 'string' ? false : sortDescriptor.desc;

    if (!(selector in this.indexProperties)) {
      return [];
    }

    if (this.sortingMappings.has(selector)) {
      return [await this.buildSortByMapping(selector, desc)];
    }

    const mappingProperty = this.indexProperties[selector];

    if (mappingProperty.type === 'nested') {
      const sortRules = [];
      Object.entries(mappingProperty.properties).forEach(([key, nestedMappingProperty]) => {
        if (nestedMappingProperty.type === 'keyword') {
          sortRules.push({
            [`${selector}.${key}`]: {
              mode: 'max',
              order: desc ? 'desc' : 'asc',
              nested: {
                path: selector,
              },
            },
          });
        }
      });

      return sortRules;
    }

    if (mappingProperty.type === 'boolean') {
      return [this.getBooleanFieldsSortScript(selector, desc)];
    }

    if (new Set(['keyword', 'date', 'integer']).has(mappingProperty.type)) {
      return [
        {
          [selector]: desc ? 'desc' : 'asc',
        },
      ];
    }

    return [];
  };

  protected buildSortByMapping = async (selector, desc): Promise<{ _script: ScriptSort }> => {
    const params = await firstValueFrom(this.sortingMappings.get(selector));
    return {
      _script: {
        type: 'string',
        script: {
          lang: 'painless',
          source: `
            if (doc['${selector}'].empty || params[doc['${selector}'].value] == null) {
              return 'zzzzzz';
            }

            return params[doc['${selector}'].value];
          `,
          params,
        },
        order: desc ? 'desc' : 'asc',
      },
    };
  };

  protected getBooleanFieldsSortScript = (selector, desc): { _script: ScriptSort } => {
    return {
      _script: {
        type: 'string',
        script: {
          lang: 'painless',
          source: `
            if (doc['${selector}'].empty || doc['${selector}'].value == null || doc['${selector}'].value == false) {
              return 'No';
            }

            return 'Yes';
          `,
        },
        order: desc ? 'desc' : 'asc',
      },
    };
  };

  protected buildSearchQuery = (
    searchExpr?: string | Function | Array<string | Function>,
    searchOperation?: SearchOperation,
    searchValue?: any,
  ) => {
    const should = [];

    const expressions: string[] = [];

    if (typeof searchExpr === 'string') {
      expressions.push(searchExpr);
    }

    if (Array.isArray(searchExpr)) {
      searchExpr.forEach(expr => {
        if (typeof expr === 'string') {
          expressions.push(expr);
        }
      });
    }

    expressions.forEach(expr => {
      if (searchOperation === 'contains') {
        should.push(this.getContainsQuery(expr, searchValue));
      }
      if (searchOperation === 'startswith') {
        should.push(this.getStartsWithQuery(expr, searchValue));
      }
    });
    const payload: SearchRequest = {
      query: {
        bool: {
          should,
        },
      },
    };

    return payload;
  };

  protected getEqualQuery = (dataField: string, value) => {
    return {
      match: {
        [dataField]: value,
      },
    };
  };

  protected getContainsQuery = (dataField: string, value) => {
    // Split the value into parts
    const parts = value.trim().split(' ');

    // Create wildcard queries for each part of the value
    const wildcardQueries = parts.map(part => ({
      wildcard: {
        [dataField]: {
          value: `*${part}*`,
          case_insensitive: true,
        },
      },
    }));

    // Combine the wildcard queries using a bool filter with 'must' condition
    return {
      bool: {
        must: wildcardQueries,
      },
    };
  };

  protected getStartsWithQuery = (dataField: string, value) => {
    return {
      wildcard: {
        [dataField]: {
          value: `${value}*`,
          case_insensitive: true,
        },
      },
    };
  };

  protected getEndWithQuery = (dataField: string, value) => {
    return {
      wildcard: {
        [dataField]: {
          value: `*${value}`,
          case_insensitive: true,
        },
      },
    };
  };

  protected getMoreQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { gt: value },
      },
    };
  };

  protected getMoreOrEqualQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { gte: value },
      },
    };
  };

  protected getLessQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { lt: value },
      },
    };
  };

  protected getLessOrEqualQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { lte: value },
      },
    };
  };

  protected getBetweenQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { gte: value[0], lte: value[1] },
      },
    };
  };

  protected getNestedQuery = (
    parentDataField: string,
    mappingNestedProperty: MappingNestedProperty,
    value,
    selectedFilterOperations: DxFilterOperators,
  ) => {
    const should = [];
    Object.entries(mappingNestedProperty.properties).forEach(([key, nestedMappingProperty]) => {
      if (nestedMappingProperty.type === 'keyword') {
        should.push(this.getQuery(`${parentDataField}.${key}`, value, selectedFilterOperations));
      }
    });

    return {
      nested: {
        path: parentDataField,
        query: {
          bool: {
            should,
          },
        },
      },
    };
  };
}
