import { Entity } from '../../lists/entity-permission.enum';
import { PermissionType } from '../../lists/permission-type.enum';
import { EntityApplications } from '../../lists/entity-applications.enum';

export enum EntityPermissionActivityKeys {
  read = 'read',
  write = 'write',
  delete = 'delete',
  create = 'create',
}

export enum EntityPermissionModelKeys {
  id = 'id',
  parentId = 'parentId',
  appId = 'appId',
  isEnable = 'isEnable',
  type = 'type',
  title = 'title',
  description = 'description',
  entitySelected = 'entitySelected',

  // read = 'read',
  // write = 'write',
  // delete = 'delete',
  // create = 'create',
}

export class EntityItem {
  [EntityPermissionModelKeys.id]: Entity;
  [EntityPermissionModelKeys.parentId]: Entity | null;
  [EntityPermissionModelKeys.appId]: EntityApplications | null;
  [EntityPermissionModelKeys.isEnable]: boolean;
  [EntityPermissionModelKeys.type]: PermissionType;
  [EntityPermissionModelKeys.title]?: string;
  [EntityPermissionModelKeys.description]?: string;
  [EntityPermissionModelKeys.entitySelected]?: boolean;
}

export class EntityPermission extends EntityItem {
  [EntityPermissionActivityKeys.read]: boolean;
  [EntityPermissionActivityKeys.write]: boolean;
  [EntityPermissionActivityKeys.delete]: boolean;
  [EntityPermissionActivityKeys.create]: boolean;
}

export type RoleEntityPermissionMap = {
  [key in keyof typeof Entity]?: EntityPermission;
};
