<p class="editor__data-grid--empty" *ngIf="!isEditable && !phoneNumbers?.length; else dataGridTmp">
  {{ emptyMessage }}
</p>

<ng-template #dataGridTmp>
  <dx-data-grid
    #dataGridRef
    class="editor editor__data-grid"
    [class.editor__data-grid--view]="!isEditable"
    [dataSource]="phoneNumbers"
    [showRowLines]="isEditable"
    [showBorders]="isEditable"
    [showColumnLines]="isEditable"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="false"
    [noDataText]="emptyMessage"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
  >
    <dxo-toolbar [visible]="isEditable">
      <dxi-item
        *ngIf="Entity.agencyContactInfoOfficePhoneNumbers | hasPermission : EntityPermissionActivityKeys.create | async"
        name="addRowButton"
      ></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="row"
      [useIcons]="true"
      [allowUpdating]="
        Entity.agencyContactInfoOfficePhoneNumbers | hasPermission : EntityPermissionActivityKeys.write | async
      "
      [allowDeleting]="
        Entity.agencyContactInfoOfficePhoneNumbers | hasPermission : EntityPermissionActivityKeys.delete | async
      "
      [allowAdding]="
        Entity.agencyContactInfoOfficePhoneNumbers | hasPermission : EntityPermissionActivityKeys.create | async
      "
    >
    </dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

    <dxi-column
      [dataField]="PhoneNumberKeys.number"
      caption="Phone Number"
      [editorOptions]="{ mask: phoneMask }"
      cellTemplate="phoneNumberCellTemplate"
    >
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_PHONE_TEXT"></dxi-validation-rule>
      <dxi-validation-rule
        type="stringLength"
        [min]="totalPhoneDigits"
        [max]="totalPhoneDigits"
        [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
      ></dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="PhoneNumberKeys.extension" caption="Extension"> </dxi-column>

    <dxi-column
      [dataField]="PhoneNumberKeys.phone_type"
      caption="Phone Type"
      [editorOptions]="{ onSelectionChanged: onPhoneTypeChanged }"
    >
      <dxo-lookup
        [dataSource]="agencyPhoneTypes$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
      ></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="PhoneNumberKeys.supportType" caption="Support Type">
      <dxo-lookup
        [dataSource]="supportTypesLookup$ | async"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="BaseModelKeys.dbId"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="PhoneNumberKeys.is_primary" caption="Primary" dataType="boolean"></dxi-column>

    <dxi-column type="buttons" [visible]="isEditable">
      <dxi-button
        [visible]="
          Entity.agencyContactInfoOfficePhoneNumbers | hasPermission : EntityPermissionActivityKeys.write | async
        "
        name="edit"
      ></dxi-button>
      <dxi-button
        [visible]="
          Entity.agencyContactInfoOfficePhoneNumbers | hasPermission : EntityPermissionActivityKeys.delete | async
        "
        name="delete"
      ></dxi-button>
    </dxi-column>
    <!-- Templates -->
    <div *dxTemplate="let cell of 'phoneNumberCellTemplate'">
      <div>
        {{ cell.data | phoneNumberMask : false }}
      </div>
    </div>
  </dx-data-grid>
</ng-template>
