import { RegistrantKeys } from '../models/registration/registrants.model';

export const REGISTRANTS_TITLE_LIST = {
  // Conference Information
  [RegistrantKeys.registeredAt]: 'Registered at',
  [RegistrantKeys.lastEvalDate]: 'Last Eval',
  [RegistrantKeys.updatedAt]: 'Updated at',
  [RegistrantKeys.createdAt]: 'Created at',
  [RegistrantKeys.primaryEmailAddress]: 'Primary Email Address',
  [RegistrantKeys.ccEmail]: 'CC Email',
  [RegistrantKeys.groupId]: 'Group ID',
  [RegistrantKeys.registrationStatus]: 'Registration Status',
  [RegistrantKeys.inviteeStatus]: 'Invitee Status',
  [RegistrantKeys.registrationSource]: 'Registration Source',
  [RegistrantKeys.registrationType]: 'Registration Type',
  [RegistrantKeys.qualifiedAs]: 'Qualified As',
  [RegistrantKeys.awardName]: 'Award Name',
  [RegistrantKeys.inviteeEmail]: 'Invitee Email',
  [RegistrantKeys.inviteeEmailType]: 'Invitee Email Type',
  [RegistrantKeys.firstTimeAttendee]: 'First Time Attendee',
  [RegistrantKeys.plaqueRequired]: 'Is Plaque Required',
  // Registrant Information
  [RegistrantKeys.firstName]: 'First Name',
  [RegistrantKeys.middleName]: 'Middle Name',
  [RegistrantKeys.lastName]: 'Last Name',
  [RegistrantKeys.badgeName]: 'Badge Name',
  [RegistrantKeys.prefix]: 'Prefix',
  [RegistrantKeys.suffix]: 'Suffix',
  [RegistrantKeys.relationship]: 'Relationship',
  [RegistrantKeys.agentId]: 'Agent ID',

  [RegistrantKeys.gender]: 'Gender',
  // [RegistrantKeys.headshotLink]: 'Headshot Link',
  [RegistrantKeys.dob]: 'Date of Birth',
  // Reservation Information
  // [RegistrantKeys.requestedArrival]: 'Requested Arrival',
  // [RegistrantKeys.arrivalDate]: 'Arrival Date',
  // [RegistrantKeys.departureDate]: 'Departure Date',
  // [RegistrantKeys.alternateDateRequest]: 'Alternate Date Request',
  // [RegistrantKeys.rsvp]: 'RSVP',
  // [RegistrantKeys.hotel]: 'Hotel',
  // [RegistrantKeys.roomType]: 'Room Type',
  // [RegistrantKeys.bedPreference]: 'Bed Preference',
  [RegistrantKeys.totalCharge]: 'Total Charge',
  [RegistrantKeys.balanceDue]: 'Balance Due',
  [RegistrantKeys.paymentMethod]: 'Payment Method',
  // Additional Information
  // [RegistrantKeys.unisexTshirtSize]: 'Unisex T-Shirt Size',
  // [RegistrantKeys.unisexTshirtSizeOther]: 'Other',
  // [RegistrantKeys.tshirtSize]: 'T-Shirt Size',
  // [RegistrantKeys.tshirtSizeOther]: 'Other',
  [RegistrantKeys.childOrAdult]: 'Child or Adult',
  [RegistrantKeys.favoriteDestination]: 'Favorite Destination',
  // Agency Information
  [RegistrantKeys.mgaId]: 'MGA',
  [RegistrantKeys.agencyId]: 'Agency',
  [RegistrantKeys.upline]: 'Upline',
  // Shipping Address
  [RegistrantKeys.isBillingDifferentFromShippingAddress]: 'Is Billing Different From Shipping Address',
  [RegistrantKeys.shippingAddress]: 'Shipping Address',
  primaryShippingAddress1: 'Address 1',
  primaryShippingAddress2: 'Address 2',
  primaryShippingCounty: 'County',
  primaryShippingCountry: 'Country',
  primaryShippingCity: 'City',
  primaryShippingState: 'State',
  primaryShippingZip: 'Zip Code',
  primaryShippingAddressType: 'Address Type',
  // Billing Address
  [RegistrantKeys.billingAddress]: 'Billing Address',
  primaryBillingAddress1: 'Address 1',
  primaryBillingAddress2: 'Address 2',
  primaryBillingCounty: 'County',
  primaryBillingCountry: 'Country',
  primaryBillingCity: 'City',
  primaryBillingState: 'State',
  primaryBillingZip: 'Zip Code',
  primaryBillingAddressType: 'Address Type',
  // Contact Information
  primaryEmail: 'Primary Email Address',
  secondaryEmail: 'Secondary Email Address',
  mobilePhone: 'Mobile Phone ',
  mobilePhoneNumber: 'Number',
  mobilePhoneType: 'Type',
  mobilePhoneExtension: 'Extension',
  secondaryPhone: 'Secondary Phone ',
  secondaryPhoneNumber: 'Number',
  secondaryPhoneExtension: 'Extension',
  secondaryPhoneType: 'Type',
  // Dietary Information
  // [RegistrantKeys.dietaryOrPersonalConsiderations]: 'Dietary or Personal Considerations?',
  // [RegistrantKeys.dietaryConsiderationType]: 'Dietary Consideration Type',
  // [RegistrantKeys.dietaryConsideration]: 'Dietary Consideration',
  // Other
  [RegistrantKeys.guestType]: 'Guest Type',
  agentStatus: 'Agent Status',
  emergencyContact: 'Emergency Contact ',
  emergencyContactFirstName: 'First Name',
  emergencyContactLastName: 'Last Name',
  emergencyContactEmailAddress: 'Email Address',
  emergencyContactPhoneNumber: 'Phone Number',
  emergencyContactAssociationType: 'Association Type',
  emergencyContactAddress1: 'Address 1',
  emergencyContactAddress2: 'Address 2',
  emergencyContactCounty: 'County',
  emergencyContactCountry: 'Country',
  emergencyContactCity: 'City',
  emergencyContactState: 'State',
  emergencyContactZip: 'Zip Code',
  emergencyContactAddressType: 'Address Type',
};
