import { Injectable } from '@angular/core';
import { AgencyKeys, Agent, AgentKeys, AGENTS_COLLECTION_NAME, ElasticSearchAgent } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../cloud-functions.service';
import { BaseElasticSearchService } from './base-elastic-search-service';
import { AgencyService } from '../agency.service';
import { map, shareReplay } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { getAgentFullName } from '../agent.service/agent-service-utils';
import { LoadOptions } from 'devextreme/data';

@Injectable({ providedIn: 'root' })
export class AgentElasticSearchService extends BaseElasticSearchService<Agent> {
  constructor(
    protected cloudFunctionsService: CloudFunctionsService,
    agencyService: AgencyService,
  ) {
    super(AGENTS_COLLECTION_NAME, {
      email_addresses: {
        nested: {
          path: 'email_addresses',
        },
      },
    });

    const agencyParams = agencyService.getList().pipe(
      map(list => {
        return list.reduce(
          (acc, agency) => {
            const agencyId = agency?.[AgencyKeys.agencyId];
            const name = agency[AgencyKeys.name];

            if (agencyId && name) {
              Object.assign(acc, { [agencyId]: name });
            }

            return acc;
          },
          {} as { [key: string]: string },
        );
      }),
      shareReplay(1),
    );
    this.sortingMappings.set(AgentKeys.p_mga_id, agencyParams);
    this.sortingMappings.set(AgentKeys.p_agency_id, agencyParams);
    this.defaultSorting = [{ [AgentKeys.p_agent_first_name]: 'asc' }, { [AgentKeys.p_agent_last_name]: 'asc' }, '_doc'];
  }

  async getById(id: string): Promise<Agent> {
    const agent = await super.getById(id);
    if (!agent) {
      return null;
    }

    return Object.assign(agent, {
      [AgentKeys.p_agent_name]: getAgentFullName(agent),
    });
  }

  async getByIds(ids: string[]): Promise<Agent[]> {
    const agents = await super.getByIds(ids);
    if (!agents) {
      return [];
    }

    return agents.map(agent =>
      Object.assign(agent, {
        [AgentKeys.p_agent_name]: getAgentFullName(agent),
      }),
    );
  }

  getAllFromElastic = async (options?: LoadOptions): Promise<Array<ElasticSearchAgent>> => {
    const agents = [];

    const getItems = async () => {
      const params = Object.assign({ skip: agents?.length, take: 500 }, options);
      const response = await this.getFromElastic(params);

      if (!response || !response?.data?.length) {
        return;
      }
      agents.push(...response.data);

      if (agents?.length === response.totalCount) {
        return;
      }

      return getItems();
    };

    try {
      await getItems();
    } catch (error) {
      return [];
    }

    return agents;
  };

  getDataSource = (options?: LoadOptions) => {
    return new DataSource({
      paginate: true,
      pageSize: 50,
      map: data => {
        return Object.assign({}, data, {
          [AgentKeys.p_agent_name]: getAgentFullName(data),
        });
      },
      store: this.getStore(options),
    });
  };

  getStore = (options?: LoadOptions) => {
    return new CustomStore({
      key: 'dbId',
      byKey: id => this.getById(id),
      load: async loadOptions => {
        const params = { ...options, ...loadOptions };
        const response = await this.getFromElastic(params);

        return response;
      },
    });
  };
}
