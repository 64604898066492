import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { AgAssignOwnerModalComponent } from './modal/ag-assign-owner-modal.component';
import { BehaviorSubject } from 'rxjs';
import { AssignedOwnerDetails } from './config/ag-assign-owner.config';
import { AgAssignOwnerFormService } from './modal/ag-assign-owner-form.service';
import { LoadOptions } from 'devextreme/data';
import { MediaSize } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-assign-owner-viewer',
  templateUrl: './ag-assign-owner-viewer.component.html',
  styleUrls: ['./ag-assign-owner-viewer.component.scss'],
})
export class AgAssignOwnerViewerComponent {
  @HostBinding('class') className = 'ag-assign-owner';
  @ViewChild('assignOwnerModalRef', { static: true }) assignOwnerModalComponent: AgAssignOwnerModalComponent;
  @Input() set owner(v: AssignedOwnerDetails) {
    this.owner$.next(v ?? null);
  }
  @Input() isDisabled = false;
  @Input() listLoadOptions: LoadOptions<any>;
  @Input() onOwnerChange: (owner: AssignedOwnerDetails) => Promise<boolean | void>;

  owner$ = new BehaviorSubject<AssignedOwnerDetails>(null);

  protected readonly MediaSize = MediaSize;

  constructor(private assignOwnerFormService: AgAssignOwnerFormService) {}

  showAssignOwnerModalClick(data?: AssignedOwnerDetails): void {
    if (data) {
      this.owner$.next(data);
    }
    this.assignOwnerModalComponent.showModal(this.owner$.value);
  }

  onUpdateOwner(data: AssignedOwnerDetails): void {
    this.onOwnerChange(data).then(val => {
      this.assignOwnerFormService.onSuccessfulOwnerCreated();
      this.owner$.next(data ?? null);
      this.assignOwnerModalComponent.hideModal();
    });
  }

  isImageLoadingIssuesChange = hasIssues => {
    if (hasIssues) {
    }
  };
}
