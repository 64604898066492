<p class="editor__data-grid--empty" *ngIf="!isEditable && !emails?.length; else dataGridTmp">
  {{ emptyMessage }}
</p>

<ng-template #dataGridTmp>
  <dx-data-grid
    #dataGridRef
    class="editor editor__data-grid"
    [class.editor__data-grid--view]="!isEditable"
    [dataSource]="emails"
    [showRowLines]="isEditable"
    [showBorders]="isEditable"
    [showColumnLines]="isEditable"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="false"
    [noDataText]="emptyMessage"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
  >
    <dxo-toolbar [visible]="isEditable">
      <dxi-item
        *ngIf="
          Entity.agencyContactInfoOfficeEmailAddresses | hasPermission : EntityPermissionActivityKeys.create | async
        "
        name="addRowButton"
      ></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="row"
      [useIcons]="true"
      [allowUpdating]="
        Entity.agencyContactInfoOfficeEmailAddresses | hasPermission : EntityPermissionActivityKeys.write | async
      "
      [allowDeleting]="
        Entity.agencyContactInfoOfficeEmailAddresses | hasPermission : EntityPermissionActivityKeys.delete | async
      "
      [allowAdding]="
        Entity.agencyContactInfoOfficeEmailAddresses | hasPermission : EntityPermissionActivityKeys.create | async
      "
    >
    </dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

    <dxi-column [dataField]="EmailAddressKeys.address" [caption]="isEditable ? 'Address' : ''">
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_EMAIL_TEXT"></dxi-validation-rule>
      <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      [dataField]="EmailAddressKeys.supportType"
      caption="Support Type"
      [editorOptions]="{ onSelectionChanged: onSupportTypeChanged }"
    >
      <dxo-lookup
        [dataSource]="supportTypesLookup$ | async"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="BaseModelKeys.dbId"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="EmailAddressKeys.emailType"
      caption="Email Type"
      [editorOptions]="{ onSelectionChanged: onEmailTypeChanged }"
    >
      <dxo-lookup
        [dataSource]="emailTypeLookup$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
      ></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="'is_primary'" caption="Primary" dataType="boolean"></dxi-column>

    <dxi-column type="buttons" [visible]="isEditable">
      <dxi-button
        [visible]="
          Entity.agencyContactInfoOfficeEmailAddresses | hasPermission : EntityPermissionActivityKeys.write | async
        "
        name="edit"
      ></dxi-button>
      <dxi-button
        name="delete"
        [visible]="
          canDeleteEmailAddress && Entity.agencyContactInfoOfficeEmailAddresses
            | hasPermission : EntityPermissionActivityKeys.delete
            | async
        "
      ></dxi-button>
    </dxi-column>
  </dx-data-grid>
</ng-template>
