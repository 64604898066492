<ag-shr-modal-window
  #needToKnowModalRef
  [title]="title"
  [width]="500"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
  [showSaveButton]="needToKnowFormData | needToKnowPermissions | async"
  (onSaveClick)="handleSaveNeedToKnow($event)"
  (onPopupClose)="handleNeedToKnowFormPopupClose($event)"
>
  <dx-form
    #needToKnowFormRef
    [(formData)]="needToKnowFormData"
    labelMode="floating"
  >
    <dxi-item
      [dataField]="BaseModelKeys.createdBy"
      [label]="{ text: 'Created By' }"
      editorType="dxSelectBox"
      [editorOptions]="{
        readOnly: true,
        items: agentsDataSource$ | async,
        valueExpr: 'value',
        displayExpr: 'description',
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="BaseModelKeys.createdDate"
      [label]="{ text: 'Created Date' }"
      editorType="dxDateBox"
      [editorOptions]="dateTimeEditorOptions"
    ></dxi-item>

    <dxi-item
      [dataField]="NeedToKnowKeys.visibilityLevel"
      [label]="{ text: 'View Type' }"
      editorType="dxSelectBox"
      [editorOptions]="{
        readOnly: !(needToKnowFormData | needToKnowPermissions | async) || isVisibilityTypeLocked,
        items: needToKnowVisibilityLevelLookup,
        valueExpr: 'value',
        displayExpr: 'description',
        searchEnabled: true,
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="NeedToKnowKeys.needToKnow"
      [isRequired]="true"
      [label]="{ text: 'Need To Know' }"
      [editorType]="'dxTextArea'"
      [editorOptions]="{ readOnly: !(needToKnowFormData | needToKnowPermissions | async), height: 150 }"
    ></dxi-item>
  </dx-form>
</ag-shr-modal-window>
